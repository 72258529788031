<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true" v-if="canSell">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva venta
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar ventas por folio de ticket" single-inline hide-details
                        @keydown.enter="searchVenta">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte ventas dirias</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-data-table dense :headers="filteredHeaders" :items="sell" sort-by="idVenta" class="elevation-1" 
                    :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>
                        <template v-slot:[`item.actions`] ="{ item }">
                            <v-btn icon small color="primary p-2" @click="detalleVenta(item)">
                                <v-icon>fas fa-clipboard-list</v-icon>
                            </v-btn>
                            <!-- <v-btn icon small color="red p-2" @click="cancelarVenta(item)">
                                <v-icon>fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </template>
                        <template v-slot:[`item.tipoSalida`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="item.tipoSalida.color" small dense filled label> {{ item.tipoSalida.nombre}} </v-chip>
                        </template>
                        <template v-slot:[`item.ventaEstatus`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="item.colorEstatus"
                                small dense filled label v-if="item.ventaEstatus != null "> {{ item.ventaEstatus }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.subtotal`]= "{ item }">
                            <span>$ {{item.subtotal}}</span>
                        </template>
                        <template v-slot:[`item.total`]= "{ item }">
                            <span>$ {{item.total}}</span>
                        </template>
                        <template v-slot:[`item.eliminado`]="{ item }">
                            <v-chip :color="colorstatus(item.eliminado)" dark v-text="item.eliminado == 'true' ? 'Inactivo' : 'Activo'">
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>
            </v-card>
        </v-col>
        <ModalNuevaVenta :dialog.sync="dialog" :initialize.sync="initialize" :handleModal="handleModal" v-if="dialog"
        :monedas.sync="monedas" :clientes.sync="clientes" :tipoSalida.sync="tipoSalida"
        :tipoPagos.sync="tipoPagos" :sucursales.sync="sucursales"/>
        <DetalleSale :detalleOpen.sync="detalleOpen" :detailsVenta.sync="detailsVenta" :handleModal="handleDetalleModal" v-if="detalleOpen"/>
        <ExcelExportVenta :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport"
        :tipo-salida.sync="tipoSalida" :sucursales.sync="sucursales" :is-admin="isAdmin"
        v-if="dialogExcelExport"/>
    </v-row>
</template>
<script>
import { getParamsPagination } from '@/utils/pagination';
import TipoMonedaDataService from '@/service/catalogo/tipoMoneda/tipoMonedaDataService';
import ClienteDataService from '@/service/cliente/clienteDataService';
import TipoSalidaDataService from '@/service/venta/TipoSalidaDataService';
import TipoPagoDataService from '@/service/catalogo/tipoPago/tipoPagoDataService';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';

import VentaDataService from '@/service/venta/VentaDataService';
import ModalNuevaVenta from "./NuevaVenta.vue";
import ExcelExportVenta from './ExcelExportVenta.vue';
import PaginationNumber from '@/components/shared/PaginationNumber.vue';
import DetalleSale from './DetalleSale.vue';
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: "ListaVenta",
    components: { ModalNuevaVenta, DetalleSale, PaginationNumber, ExcelExportVenta, Columns},
    created() {
        this.initialize();
        this.getMoneda();
        this.getAllCliente();
        this.getAllTipoSalida();
        this.getAllTipoPago();
        this.getAllSucursal();
    },
    data() {
        return {
            monedas: [],
            clientes: [],
            tipoSalida: [],
            tipoPagos: [],
            sucursales: [],

            headers: [
                { text: "Detalle", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "idVenta", visible: false, groupable: false, show: false },
                { text: 'Sucursal salida', value: 'sucursalEnvia.nombre', groupable: false, show: true },
                { text: 'Sucursal recibe', value: 'sucursalRecibe.nombre', groupable: false, show: true },
                { text: "Folio", value: "ticketFolio", groupable: false, show: true },
                { text: 'Fecha salida', value: 'registerDate', groupable: false, show: true },
                { text: 'Tipo salida', value: 'tipoSalida', groupable: false, show: true },
                { text: 'Estatus', value: 'ventaEstatus', groupable: false, show: true },
                { text: "Cliente", value: "cliente.nombreComercial", groupable: false, show: true },
                { text: "Forma Pago", value: "tipoPago.formaPago", groupable: false, show: true },
                { text: "Total", value: "totalNatural", groupable: false, show: true },
                { text: "Saldo", value: "saldoNatural", groupable: false, show: true },
                { text: "Abonado", value: "abonadoNatural", groupable: false, show: true },
            ],
            search: "",
            page: 1,
            totalPages: 0,
            itemsPerPage: 25,
            totalElements: 0,
            sell: [],

            dialog: false,
            //Details sell
            detalleOpen: false,
            dialogExcelExport: false,
            detailsVenta: {}
        }
    },
    methods: {
        searchVenta() {
            this.page = 1;
            this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
        },
        initialize( options = {} ) {
            const params = getParamsPagination({ options, search: this.search });
            this.itemsPerPage = options.itemsPerPage;
            VentaDataService.table(params).then( resp => {
                const { rows, totalElements, totalPages, currentPage } = resp.data;
                this.sell = rows;
                this.totalElements = totalElements;
                this.page = currentPage + 1 ;
                this.totalPages = totalPages;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        detalleVenta( item ){
            this.detailsVenta = item;
            this.detalleOpen = true;
        },
        handleExcelExport(){
            this.dialogExcelExport = !this.dialogExcelExport
        },
        cancelarVenta( item ){
            this.$swal.fire({
                title: '¿Está seguro de cancelar la venta?',
                text: "La venta será cancelada y no se podrá recuperar",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancelarla!',
                cancelButtonText: 'No, cancelar!'
            }).then( result => {
                if (result.value) {
                    VentaDataService.cancelarVenta(item.id).then(resp => {
                        if( resp.status == 200 ){
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Venta cancelada', text: 'La venta ha sido cancelada', showConfirmButton: false, timer: 3500 });
                            this.initialize();
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al cancelar la venta', text: err.reponse.data, showConfirmButton: false, timer: 3500 });
                    })
                }
            })
        },
        colorstatus(status) { return (!status) ? "green" : "red"; },
        handleModal() { this.dialog = !this.dialog; },
        handleDetalleModal() { this.detalleOpen = !this.detalleOpen; },
        // LOAD CATALOGS
        async getMoneda(){ this.monedas = await TipoMonedaDataService.getAllTipoMoneda().then( resp => resp.data ) || [] },
        async getAllCliente(){ this.clientes = await ClienteDataService.getAll().then( resp => resp.data ) || [] },
        async getAllTipoSalida(){ this.tipoSalida = await TipoSalidaDataService.getAllTipoSalida().then( resp => resp.data ) || [] },
        async getAllTipoPago(){ this.tipoPagos = await TipoPagoDataService.allTipoPago().then( rsp => rsp.data ) || []},
        async getAllSucursal(){ this.sucursales = await SucursalDataService.lstSucursal().then( resp => resp.data ) || [] }
    },
    computed:{
        canSell(){ return UsuarioDataService.getUserRoles().includes('ROLE_VENDEDOR') || this.isAdmin; },
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
        filteredHeaders(){ return this.headers.filter( h => h.show ); }
    }
};
</script>
