<template>
    <v-row justify="end" class="pagination-container">
      <v-col cols="12" md="2" lg="2" sm="4">
        <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid }">
          <v-text-field
            label="Elementos por pagina*"
            type="number"
            prepend-icon="fas fa-sort-numeric-up"
            persistent-hint
            required
            :value="itemsPerPage"
            min="1"
            :max="totalElements"
            outlined
            dense
            @input="(e) => handleItemPerPage(e)"
            :error-messages="errors"
            :success="valid"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="auto mt--2" md="auto" lg="auto" sm="4">
        <v-chip class="pagination-info mt-1" color="primary" text-color="white" label medium pill>
            Pagina {{ page }} de {{ pageCount }} - Total registros :  {{ totalElements }}
        </v-chip>
      </v-col>
      <v-col cols="auto" md="auto" lg="auto" sm="4">
        <v-pagination :value="page" :length="pageCount"
        total-visible="10" @input="handlePage"
        prev-icon="fas fa-chevron-left" next-icon="fas fa-chevron-right">
        </v-pagination>
        <!-- Add any additional elements here -->
      </v-col>
    </v-row>
  </template>  
<script>
export default {
    name: "PaginationNumber",
    props: {
        itemsPerPage: { type: Number, default: 25 },
        pageCount: { type: Number, default: 10 },
        totalElements: { type: Number, default: 0 },
        page: { type: Number, default: 1 },
        initialize: { type: Function }
    },
    methods: {
        loadTable({ itemsPerPage = 25, page = 1 }) {
            this.initialize({ page, itemsPerPage });
        },
        handleItemPerPage(e) {
            let itemsPerPage = 25;
            if(e != null && e > 0) {
                itemsPerPage = parseInt(e);
            }
            if(itemsPerPage <= this.totalElements) {
                this.loadTable({ page: this.page, itemsPerPage });
            }
        },
        handlePage(page) {
            this.loadTable({ page, itemsPerPage: this.itemsPerPage });
        }
        
    }
}
</script>