<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
            <v-card outlined shaped class="elevation-4">
                <ValidationObserver ref="refVenta" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="saveProducto">       
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                <div class="text-h6 font-weight-bold white--text text-uppercase">
                                    VENTA MOSTRADOR - Granja san Fernando S.A DE C.V.
                                </div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn color="primary" type="submit" tile dark align="right"  :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar venta 
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="9" md="9" sm="12">
                                    <v-row>
                                        <!-- <v-col cols="12" sm="4" md="4">
                                            <v-text-field label="Ticket de venta*" prepend-inner-icon="fas fa-ticket-alt"
                                            solo-inverted
                                            disabled v-model="venta.ticketFolio"></v-text-field>
                                        </v-col> -->
                                        <v-col cols="12" sm="4" md="4">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="tipoSalida" item-text="nombre" item-value="id"
                                                prepend-inner-icon="fas fa-outdent" hint="Selecciona el tipo de salida."
                                                v-model="venta.tipoSalidaId" label="Tipo salida" required outlined 
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeMovement">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" v-model="venta.sucursalEnviaId"
                                                label="Sucursal envia" required outlined prepend-inner-icon="fas fa-id-card-alt"
                                                :readonly="!isAdmin"
                                                hint="Seleccione la sucursal a trasladar" :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeMovement">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="listEntradaDetalle" item-text="ticketFolio" item-value="id" v-model="venta.entradaDetalleId"
                                                label="Entrada de inventario" required outlined prepend-inner-icon="fas fa-layer-group"
                                                hint="Seleccione la entrada de inventario" :error-messages="errors" :success="valid">
                                                <!-- hide-details hide-selected -->
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            No hay entradas
                                                            <strong> para la sucursal seleccionada</strong>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    {{ item.ticketFolio }} - {{ item.producto.nombre }}. Existencia: <strong> {{ item.existencia }}</strong>
                                                </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeMovement">
                                            <ValidationProvider :rules="modeMerma ? '' : 'required'" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="excludeSucursalEnvia" item-text="nombre" item-value="id" v-model="venta.sucursalRecibeId"
                                                label="Sucursal recibe" required outlined prepend-inner-icon="fas fa-id-card-alt"
                                                hint="Seleccione la sucursal a trasladar" :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeSell">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                                <v-autocomplete :items="clientes" item-text="nombreComercial" item-value="idCliente" v-model="venta.clienteId"
                                                label="Cliente" required outlined prepend-inner-icon="fas fa-id-card-alt" hint="Seleccione un cliente, es opcional"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeSell">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="tipoPagos" item-text="formaPago" item-value="id" v-model="venta.tipoPagoId"
                                                label="Medio de Pago" outlined prepend-inner-icon="fas fa-file-invoice-dollar"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4" v-if="modeSell">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="monedas" item-text="moneda" item-value="id" v-model="venta.tipoMonedaId"
                                                label="Moneda*" required solo-inverted prepend-inner-icon="fas fa-wallet" disabled
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" v-if="modeSell">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="paymentMethod" item-text="name" item-value="value"
                                                v-model="venta.paymentMethod" label="Metodo de pago*" prepend-icon="fas fa-credit-card"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" v-if="enableOperationNumber">
                                            <ValidationProvider :rules="enableOperationNumber ? 'required': ''" v-slot="{ errors, valid }" :immediate="true">
                                                <v-text-field label="Numero/referencia de la operación" prepend-icon="fas fa-receipt"
                                                v-model="venta.operationNumber" :error-messages="errors" :success="valid" required :counter="100">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-chip class="text-h3 font-weight-regular white--text filterSubtotal"
                                            :color="modeMerma ? 'red darken-3' : 'green darken-4'"
                                            label text-color="white" large>
                                                <v-icon left>fas fa-dollar-sign</v-icon> {{importeNatural}}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <!-- <v-autocomplete :items="productos" item-text="nombre" item-value="id"
                                            :search-input.sync="search" :loading="isLoading" hide-selected
                                            chips outlined v-model="venta.productoId" label="Escriba el nombre del producto" 
                                            prepend-inner-icon="fas fa-store-alt" hint="Buscar los productos que desee vender">
                                            </v-autocomplete> -->
                                        
                                            <addProducto :venta.sync="venta" :products.sync="products" ref="myCartProduct"
                                            :listEntradaDetalle.sync="listEntradaDetalle"
                                            @getDetailProduct="getDetailProduct" @addProductCart="addProductCart">
                                            </addProducto>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="3" sm="12" lg="3">
                                    <v-alert class="calendario" color="primary" icon="fas fa-calendar-check" prominent type="success">
                                        {{fullDateNatural}}
                                    </v-alert>
                                    <!-- Details product -->
                                    <DetailProductInStock :product-in-stock="detailProduct" v-if="showDetail && modeSell"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ProductoDataService from "@/service/producto/ProductoDataService";
import ProductoListaPrecioDataService from "@/service/producto/ProductoListaPrecioDataService";
import VentaDataService from '@/service/venta/VentaDataService';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import addProducto from './addProduct.vue';
import DetailProductInStock from "./DetailProductInStock.vue";
import { getFullDateTodayNatural, getHourNowNatural } from "@/utils/DateUtil";
import EntradaDetalleDataService from "@/service/entrada/EntradaDetalleDataService";
import UsuarioDataService from "@/service/usuario/usuarioDataService";
export default {
    name: "NuevaVenta",
    components: { addProducto, DetailProductInStock },
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        initialize: { type: Function },
        monedas: { type: Array },
        clientes: { type: Array },
        tipoSalida: { type: Array },
        tipoPagos: { type: Array },
        sucursales: { type: Array },
    },
    created () { this.getAllServices() },
    data () {
        return {
            products:   [],
            services:   [],
            listEntradaDetalle: [],
            detailProduct:  [],
            importeNatural: 0,
            venta: {
                sucursalEnviaId: null,
                sucursalRecibeId: null,
                clienteId:      null,
                entradaDetalleId: null,
                tipoPagoId:     1,
                tipoMonedaId:   1,
                tipoSalidaId:   1,
                ticketFolio:    '',
                total:          0,
                productoId:     null,
                eliminado:      false,
                paymentMethod:  '',
                operationNumber: ''
            },
            productCart: [],
            paymentMethod: [ 'EFECTIVO', 'TRANSFERENCIA', 'CHEQUE'],
            search: null,
            isLoading: false
        }
    },
    methods: {
        // TO GET ALL PRODUCTS in MERMAS and SALIDAS.
        async getAllProduct(){ this.products = await ProductoDataService.findAll().then( resp => resp.data ) || [] },
        async getAllServices(){
            const typeService = true;
            this.services = await ProductoDataService.findAll(typeService).then( resp => resp.data ) || []
        },
        async getUltimateSell(){
            this.ultimateSell = await VentaDataService.ultimateSell().then( resp => resp.data.row[0] ) || [];
            this.venta.ticketFolio = this.ultimateSell.ticketFolio;
        },
        
        async getListaPrecioProducto( clienteId ){
            const productPrice = await ProductoListaPrecioDataService.findByClienteId( clienteId ).then( resp => resp.data ) || [];
            if( productPrice.length == 0 ){
                this.$swal.fire({ icon: 'error', title: 'No hay precio asignado para este cliente',
                text:'Por lo tanto, no sera posible vender, favor de solicitar asignar precio con su administrador de clientes',
                showConfirmButton: false, timer: 5000 });
            } else {
                const products =  productPrice.map( prodClient => {
                    const product = prodClient.producto;
                    product.precioVenta = prodClient.precioVenta;
                    product.listaPrecioId = prodClient.listaPrecioId;
                    return product;
                });
                const services = this.services.map( service => {
                    const product = service;
                    product.precioVenta = service.amountSell;
                    product.listaPrecioId = null;
                    return product;
                })
                this.products = [...products, ...services];
            }
        },
        addProductCart(products){
            this.productCart = products;
            const total = products.reduce( (acm, item) => acm + item.total, 0 );
            this.venta.total = total;
            this.importeNatural = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        getDetailProduct( data ){
            this.detailProduct = data;
        },
        getListaEntradaDetalle( sucursalId ){
            EntradaDetalleDataService.getEntradaDetalleBySucursal( sucursalId ).then( result => {
                this.listEntradaDetalle = result.data || [];
            }).catch( err => {
                this.$swal.fire({icon: 'error', title: 'Entradas no encontradas', html: err, showConfirmButton: false, timer: 3500 });
            })
        },
        selectedProductInEntradaDetalle( entradaDetalleId ){
            if( this.listEntradaDetalle.length > 0 && entradaDetalleId > 0 ){
                this.products = this.listEntradaDetalle.filter( productEntry => productEntry.id == entradaDetalleId )
                .map( productEntryDetail => {
                    let product = productEntryDetail.producto;
                    product.precioVenta = productEntryDetail.precioUnitario;
                    product.listaPrecioId = null
                    product.entradaDetailId = productEntryDetail.id
                    return product;
                })
            }
        },
        saveProducto(){
            this.$refs.refVenta.validate().then( isValid => {
                if( isValid ){
                    if ( this.productCart.length == 0){
                        this.$swal.fire({ type : 'warning', title : 'No es posible generar una venta, si no hay al menos un producto.', showConfirmButton : false, timer : 2500 });
                        return;
                    }
                    VentaDataService.create( this.venta, this.productCart ).then( rsp => {
                        this.initialize();
                        this.handleModal();
                        this.$swal.fire({ icon: 'success', title: 'Venta registrada', text: rsp.data.message, showConfirmButton: false, timer: 3500 });
                    }).catch( err => {
                        this.$swal.fire({icon: 'error', title: 'Venta no registrado, validar campos', text: err.response.data, showConfirmButton: false, timer: 3500 });	
                    });
                }
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Venta no registrado, validar campos', text: err, showConfirmButton: false, timer: 3500 });
            })
        }
    },
    watch: {
        'venta.tipoSalidaId': function( newValue ){
            this.venta.sucursalRecibeId = null
            if(newValue == 2){
                this.venta.sucursalEnviaId = SucursalDataService.getCurrentSucursalId();
                this.venta.sucursalRecibeId = SucursalDataService.getCurrentSucursalId();
            }
            if( newValue == 2 || newValue == 3 ){
                this.venta.sucursalEnviaId = SucursalDataService.getCurrentSucursalId();
                // this.venta.sucursalRecibeId = SucursalDataService.getCurrentSucursalId()
                this.venta.clienteId = null;
                // this.getAllProduct();
            }

            this.$refs.myCartProduct.productCart = [];
            this.products = [];
            this.venta.entradaDetalleId = null;
            if( newValue == 1 ){
                this.detailProduct = [];
                if( this.venta.clienteId != null ){
                    this.getListaPrecioProducto( this.venta.clienteId );
                }
            }
        },
        'venta.sucursalEnviaId': function( newValue ){
            if( newValue != null ){
                this.getListaEntradaDetalle( newValue );
            }
        },
        'venta.clienteId':function( newValue ){
            if( newValue != null ){
                this.$refs.myCartProduct.productCart = [];
                this.getListaPrecioProducto( newValue );
            }
        },
        'venta.entradaDetalleId': function( newValue ){
            this.$refs.myCartProduct.productCart = [];
            this.products = [];
            if( newValue != null ){
                this.selectedProductInEntradaDetalle( newValue );
            }
        },
        search() {
            if (this.productos.length > 0) return
            this.isLoading = true
            ProductoDataService.findAll().then( resp => {
                this.productos = resp.data.rows;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Error al cargar los productos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            }).finally( () => this.isLoading = false )
        }
    },
    computed: {        
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
        fullDateNatural() { return getFullDateTodayNatural() + " - " + getHourNowNatural(); },
        modeSell() { return this.venta.tipoSalidaId == 1; },
        modeMovement() { return this.venta.tipoSalidaId == 2 || this.venta.tipoSalidaId == 3; },
        modeMerma() { return this.venta.tipoSalidaId == 2; },
        showDetail() {
            return this.modeSell && this.detailProduct.length > 0;
        },
        excludeSucursalEnvia() {
            return this.sucursales.filter( sucursal => sucursal.id != this.venta.sucursalEnviaId );
        },
        enableOperationNumber(){
            return this.venta.paymentMethod == "TRANSFERENCIA" || this.venta.paymentMethod == "CHEQUE";
        },
        isContado(){
            return this.venta.tipoPagoId == 1;
        }
    }
}
</script>
<style scoped>
    .busqueda {
        margin-bottom: 0in;
    }
    .v-input .v-label{
        font-size: 20px;
        font-weight: 600;
    }
</style>