<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de pagos a proveedor en excel</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" :required-input="true" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" :min-date="startDate" :required-input="true"/>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="providers" item-text="razonSocial" item-value="idProveedor"
                        prepend-inner-icon="fas fa-outdent" hint="Seleccionar proveedor."
                        v-model="providerId" label="Proveedores" outlined clearable>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click.native="downloadExcelPago">Descargar Excel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import SingleCalendar from '@/components/shared/SingleCalendar.vue';
    import ProveedorPagoDataService from '@/service/catalogo/proveedor/pago/proveedorPagoDataService';
    import { getDateLocaleNatural } from '@/utils/DateUtil';
import { required } from 'vee-validate/dist/rules';

    export default {
        name: "ExcelExporterPago",
        props: {
            dialog: { type: Boolean, default: false },
            handleModal: { type: Function },
            providers: { type: Array, required: true },
        },
        components: { SingleCalendar },
        data() {
            return {
                startDate: '',
                endDate: '',
                providerId: null,
            }
        },
        methods: {
            downloadExcelPago() {
                const startDate = getDateLocaleNatural(this.startDate);
                const endDate = getDateLocaleNatural(this.endDate);
                this.$swal.fire({
                    title: 'Quieres continuar con la descarga del reporte de pagos?',
                    text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        try {
                            return await ProveedorPagoDataService.downloadExcelPago({ startDate, endDate, providerId: this.providerId });
                        } catch (error) {
                            this.$swal.showValidationMessage(`Petición fallida: ${error}`);
                        }
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value.status == 204) {
                            this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                        } else {
                            const url = window.URL.createObjectURL(new Blob([result.value.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            const providerName = this.providers.find(provider => provider.id === this.providerId)?.nombre ?? '';
                            const fileName = startDate && endDate ? `Historial_de_pagos_proveedor_${startDate} - ${endDate}` : `Historial_de_pagos_proveedor`;
                            link.setAttribute('download', fileName + `_${providerName}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            this.$swal.fire({ icon: 'success', title: 'Reporte', text: 'Reporte descargado con éxito', showConfirmButton: false, timer: 2500 });
                        }
                    }
                }).catch(err => {
                    this.$swal.fire({ icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
                });
            }
        }
    }
</script>