<template>
    <v-row align="center" justify="center">
        <v-col cols="12" class="mb-0">
            <v-card class="elevation-1">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Registrar pago
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start"
                            title="Fecha inicio" />
                        <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" />
                        <v-divider class="mx-2" inset vertical></v-divider>
                    </v-toolbar>
                    <v-col cols="12" md="6" sm="12"></v-col>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                            label="Buscar registros de pago" single-inline hide-details
                            placeholder="Buscar por razonSocial, folio, numero de operación"
                            @keydown.enter="searchPago">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark
                                    @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte de pagos</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-data-table dense :headers="filteredHeaders" :items="listaPago" class="elevation-2" :loading="loading"
                        loading-text="Cargando... espera un momento" :options.sync="options" :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ],
                        showFirstLastPage: true,
                        firstIcon: 'fas fa-angle-double-left',
                        lastIcon: 'fas fa-angle-double-right',
                        prevIcon: 'fas fa-angle-left',
                        nextIcon: 'fas fa-angle-right'
                    }" :server-items-length="totalListaPago">
                        <template v-slot:[`item.actions`] ="{ item }">
                            <v-btn icon small color="primary p-2" @click="showDetail(item)"><v-icon>fas fa-clipboard-list</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
        <NuevoPago :dialog="dialog" :handleModal="handleModal" :clients.sync="clients"
        :initialize.sync="initTablePago" v-if="dialog"/>
        <DetailPayment :dialog="dialogDetail" :handleModal="handleModalDetail" :pago="pago" v-if="dialogDetail"/>
        <ExcelExporterPago :dialog="dialogExcelExport" :handleModal="handleModalExcelExport" v-if="dialogExcelExport"/>
    </v-row>
</template>
<script>
import VentaPagoDataService from '@/service/venta/pago/VentaPagoDataService';
import ClienteDataService from '@/service/cliente/clienteDataService';
import { getFirstDayOfCurrentMonth, getToday } from '@/utils/DateUtil';
import { getParamsPagination } from '@/utils/pagination';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import NuevoPago from './NuevoPago.vue';
import DetailPayment from './DetailPayment.vue';
import ExcelExporterPago from './ExcelExporterPago.vue';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: "ListaPago",
    components: { SingleCalendar, NuevoPago, DetailPayment, ExcelExporterPago, Columns },
    created() {
        this.getClients();
    },
    data() {
        return {
            clients: [],
            listaPago: [],
            datesRange: {
                start: getFirstDayOfCurrentMonth().toFormat('yyyy-MM-dd'),
                end: getToday().toFormat('yyyy-MM-dd')
            },
            options: {},
            totalListaPago: 0,
            loading: true,
            search: '',
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'Folio de pago', value: 'folio', groupable: false, show: true },
                { text: 'Cliente', value: 'client.razonSocial', groupable: false, show: true },
                { text: 'Fecha de pago', value: 'paymentDateNatural', groupable: false, show: true },
                { text: 'Monto pagado', value: 'payedAmountNatural', groupable: false, show: true },
                { text: 'Forma de pago', value: 'paymentMethodNatural', groupable: false, show: true },
                { text: 'Folio de operación', value: 'operationNumber', groupable: false, show: true },
            ],
            dialog: false,
            dialogDetail: false,
            dialogExcelExport: false,
        }
    },
    methods: {
        searchPago(){
            this.options.page = 1;
            this.initTablePago(this.options);
        },
        initTablePago( options = {} ){
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search,
                params: {
                    startDate: this.datesRange.start, endDate: this.datesRange.end
                }
            });
            VentaPagoDataService.table( params ).then( resp => {
                const { rows, totalElements } = resp.data;
                this.listaPago = rows;
                this.totalListaPago = totalElements;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Falló la carga de información', html: err.response.data, timer: 5000 });
            }).finally( () => {
                this.loading = false;
            });
        },
        handleExcelExport(){
            this.dialogExcelExport = !this.dialogExcelExport
        },
        getClients(){
            ClienteDataService.getAll().then( resp => {
                this.clients = resp.data;
            })
        },
        showDetail( pago ){
            this.pago = pago;
            this.dialogDetail = true;
        },
        handleModal(){ this.dialog = !this.dialog; },
        handleModalDetail(){ this.dialogDetail = !this.dialogDetail; },
        handleModalExcelExport(){ this.dialogExcelExport = !this.dialogExcelExport; }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTablePago(val);
                }
            }, deep: true
        },
        'datesRange.start': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTablePago(this.options);
            }
        },
        'datesRange.end': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTablePago(this.options);
            }
        }
    },
    computed: {
        filteredHeaders(){ return this.headers.filter( header => header.show ); }
    }
}
</script>