<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
            <v-card outlined shaped class="elevation-4">
                <ValidationObserver ref="refPago" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="savePago">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                <div class="text-h6 font-weight-bold white--text">
                                    Registrar nuevo pago
                                </div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn color="primary" type="submit" tile dark align="right"  :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar pago 
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4" class="pa-0">
                                    <SingleCalendar refElement="paymentDate" title="Fecha de pago"
                                    :input-date.sync="pago.paymentDate" :disabled="true"/>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-autocomplete :items="clients" item-text="razonSocial" item-value="idCliente"
                                        prepend-inner-icon="fas fa-outdent" hint="Seleccionar cliente."
                                        v-model="pago.clientId" label="Clientes" required outlined 
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|numeric|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field type="number" label="Monto*" prepend-icon="fas fa-dollar-sign"
                                        required v-model.number="pago.payedAmount" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                        <v-autocomplete :items="paymentMethod" item-text="name" item-value="value"
                                        v-model="pago.paymentMethod" label="Metodo de pago*" prepend-icon="fas fa-credit-card"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" v-if="enableOperationNumber">
                                    <ValidationProvider :rules="enableOperationNumber ? 'required': ''" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Numero/referencia de la operación" prepend-icon="fas fa-receipt"
                                        v-model="pago.operationNumber" :error-messages="errors" :success="valid" required :counter="100">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <AddPayment :pago="pago" @addPagos="addPagos" />
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import { getToday } from '@/utils/DateUtil';
import AddPayment from './AddPayment.vue';
import VentaPagoDataService from '@/service/venta/pago/VentaPagoDataService';
import { getFormatCurrency } from '@/utils/CurrencyUtil';

export default {
    name: "NuevoPago",
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        clients: { type: Array, required: true },
        initialize: { type: Function }
    },
    components: { SingleCalendar, AddPayment },
    data() {
        return {
            paymentMethod: [ 'EFECTIVO', 'TRANSFERENCIA', 'CHEQUE'],
            pago: {
                id: null,
                clientId: null,
                paymentDate: getToday().toFormat('yyyy-MM-dd'),
                payedAmount: 0,
                paymentMethod: '',
                operationNumber: '',
                payments: []
            }
        }
    },
    methods: {
        addPagos(pago) {
            this.pago.payments = pago;
        },
        savePago() {
              this.$refs.refPago.validate().then( isValid => {
                if( isValid ){
                    if(this.getPayedAmountTotal !== this.pago.payedAmount){
                        this.$swal.fire({ icon: 'warning', title: `No corresponde el monto a pagar ${this.getPayedAmountNatural}, con la suma de los pagos: ${this.getPayedAmountTotalNatural}`, showConfirmButton: false, timer: 2500 });
                        return
                    }
                    VentaPagoDataService.store(this.pago).then( _ => {
                        this.initialize();
                        this.handleModal();
                        this.$swal.fire({ icon: 'success', title: 'Se ha registrado el pago', showConfirmButton: false, timer: 2500 });
                    }).catch( error => {
                        this.$swal.fire({ icon: 'error', title: 'Pago no registrado', html: error.response.data, showConfirmButton: false, timer: 2500 });
                    })
                }
              }).catch( error => {
                this.$swal.fire({ icon: 'error', title: 'Pago no registrado', html: error.response.data, showConfirmButton: false, timer: 2500 });
              })
        }
    },
    computed: {
        getPayedAmountTotal() {
            const payments = this.pago.payments.filter(payment => payment.activo == true );
            return payments.reduce((total, payment) => total + payment.payedAmount, 0);
        },
        getPayedAmountTotalNatural() {
            return getFormatCurrency(this.getPayedAmountTotal, '$ ');
        },
        getPayedAmountNatural() {
            return getFormatCurrency(this.pago.payedAmount, '$ ');
        },
        enableOperationNumber(){
            return this.pago.paymentMethod == "TRANSFERENCIA" || this.pago.paymentMethod == "CHEQUE";
        }
    }
}
</script>