import { http, authHeader } from "@/http-commons";

class VentaPagoDetalleDataService {

    findByVentaPago(ventaPagoId) {
        return http.get(`/api/v1/ventaPagoDetalle/findByVentaPago/${ventaPagoId}`, {headers: authHeader()});
    }

    findByVenta(ventaId) {
        return http.get(`/api/v1/ventaPagoDetalle/findByVenta/${ventaId}`, {headers: authHeader()});
    }

    downloadExcelPagoDetalle(params){
        return http.get("/api/v1/ventaPagoDetalle/downloadExcelPagoDetalle", { params, responseType: 'blob', headers: authHeader()});
    }

}

export default new VentaPagoDetalleDataService()