<template>
    <v-row>
        <v-col cols="12" class="d-flex justify-end" v-if="pagos.length > 0">
            <v-btn color="orange" class="mt-2 mr-2" @click="enableAll()">
                <v-icon left>fas fa-check-square</v-icon> Habilitar todos
            </v-btn>

            <v-btn color="primary" class="mt-2" @click="asignarPagos()">
                <v-icon left>fas fa-dollar-sign</v-icon> Autoasignar pagos
            </v-btn>
        </v-col>

        <v-col cols="12">
            <v-simple-table fixed-header height="auto" dense class="elevation-1">
                <template v-slot:default>
                    <thead class="text-center primary">
                        <tr>
                            <th class="text-left">Habilitar</th>
                            <th class="text-left">Folio venta</th>
                            <th class="text-left">Total</th>
                            <th class="text-left">Abonado</th>
                            <th class="text-left">Saldo</th>
                            <th class="text-left">Monto a Pagar</th>
                            <!-- <th class="text-left">Acciones</th> -->
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in pagos" :key="item.id" v-if="pagos.length > 0">
                            <td>
                                <v-checkbox v-model="item.activo"></v-checkbox>
                            </td>

                            <td>{{ item.ticketFolio }}</td>

                            <td>
                                <v-text-field :value="item.totalNatural" type="text"
                                class="font-weight-medium text-h6 green--text"
                                persistent-hint disabled>
                                </v-text-field>
                            </td>

                            <td>
                                <v-text-field :value="item.abonadoNatural" type="text"
                                class="font-weight-medium text-h6 green--text"
                                persistent-hint disabled>
                                </v-text-field>
                            </td>

                            <td>
                                <v-text-field :value="item.saldoNatural" type="text"
                                class="font-weight-medium text-h6 green--text"
                                persistent-hint disabled>
                                </v-text-field>
                            </td>

                            <td>
                                <ValidationProvider :rules="!item.activo ? '' : 'required|min_value:1|max_value:' + item.saldo+'' "
                                v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field v-model.number="item.payedAmount" :value="item.payedAmount" type="number" class="mt-2 font-weight-black text-h6"
                                        outlined persistent-hint required min="1" :max="item.saldo" placeholder="0.00"
                                        :error-messages="errors" :success="valid" :disabled="!item.activo">
                                        <!-- @input="(e) => changeItem(e, index, 'cantidad')" -->
                                    </v-text-field>
                                </ValidationProvider>
                            </td>
                        </tr>

                        <tr v-if="pagos.length == 0">
                            <td colspan="6">
                                <v-alert border="left" colored-border type="success" elevation="2"
                                class=" mt-2 green--text font-weight-bold text-h5">
                                    No hay pagos pendientes
                                </v-alert>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
    import EntradaDataService from '@/service/entrada/EntradaDataService';

    export default {
        name: 'AddPayment',
        props: {
            pago: { type: Object, required: true }
        },
        data() {
            return {
                pagos: []
            }
        },
        methods: {
            getEntradaProveedor(providerId) {
                EntradaDataService.findByCreditProvider(providerId).then(response => {
                    const info = response.data;
                    this.pagos = info.map(item => {
                        return {
                            ...item,
                            providerId,
                            entradaId: item.id,
                            payedAmount: 0,
                            activo: false,
                        }
                    });
                })
            },
            deletePayment(item, index) {
            },
            asignarPagos() {
                let amount = this.pago.payedAmount;
                // set payedAmount to 0
                this.pagos.forEach(item => item.payedAmount = 0);
                this.pagos.filter(payment => payment.activo == true).forEach(payment => {
                    if(amount == 0){
                        payment.payedAmount = 0;
                        payment.activo = false;
                    }
                    if(amount > payment.saldo){
                        payment.payedAmount = payment.saldo;
                        amount = amount - payment.saldo;
                    } else {
                        payment.payedAmount = amount;
                        amount = 0;
                    }
                })
            },
            enableAll() {
                this.pagos.forEach(item => item.activo = true)
            }
        },
        watch: {
            'pago.providerId': function(newValue) {
                if (newValue != null) {
                    this.getEntradaProveedor(newValue)
                }
            },
            'pagos': function(newValue) {
                this.$emit('addPagos', newValue)
            }
        }
    }
</script>