<template>
    <div>
        <v-chip class="calendario font-weight-medium text-center" color="pink darken-2" label text-color="white">
            <v-icon left class="ml-2">fas fa-address-book</v-icon>
            <p class="text-uppercase mt-4 white--text font-weight-bold text-h7">
                Existencias: {{ productInStock[0]?.producto.nombre || '' }}
            </p>
        </v-chip>
        <v-simple-table fixed-header height="auto" dense dark class="mt-1">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Folio</th>
                        <th class="text-left">Existencia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="entry in productInStock" :key="entry.id">
                        <td class="text-truncate">{{ entry.ticketFolio }}</td>
                        <td>{{ entry.existencia }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
export default {
    name: 'DetailProductInStock',
    props: {
        productInStock: { type: Array, default: [] }
    }
}
</script>