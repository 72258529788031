import { http, authHeader } from "@/http-commons";

class ProveedorPagoDetalleDataService {

    findByProveedorPago(proveedorPagoId) {
        return http.get(`/api/v1/proveedorPagoDetalle/findByProveedorPago/${proveedorPagoId}`, {headers: authHeader()});
    }

    findByEntrada(entradaId) {
        return http.get(`/api/v1/proveedorPagoDetalle/findByEntrada/${entradaId}`, {headers: authHeader()});
    }

    downloadExcelPagoDetalle(params){
        return http.get("/api/v1/proveedorPagoDetalle/downloadExcelPagoDetalle", { params, responseType: 'blob', headers: authHeader()});
    }

}

export default new ProveedorPagoDetalleDataService()