import jsPDF from "jspdf"

const addTable = (columns, data, startY, margin, cellWidth, document) => {
    document.autoTable({
        styles: { cellWidth, margin: { left: 20 } },
        headStyles: { fontSize: 12, fillColor: [0, 105, 92] },
        head: [columns],
        body: data,
        startY,
        margin
    })
}

const generatePDFVenta = (detailVenta, itemVenta) => {
    console.log({ detailVenta, itemVenta })
    const { cliente, registerDate: fechaSalida, sucursalEnvia, tipoSalida, tipoMoneda, total, ticketFolio } = detailVenta
    const document = new jsPDF({
        orientation: 'portrait',
        format: 'A4',
        compress: true,
    });
    document.setFontSize(12);
    document.text(`${tipoSalida.nombre} ${ticketFolio}`, 65, 18)

    addTable(['Folio', 'Fecha'], [[ticketFolio, fechaSalida]], 34, { left: 125 }, 35, document);
    addTable(['Sucursal', 'Concepto'], [[sucursalEnvia.nombre, tipoSalida.nombre]], 50, { left: 125 }, 35, document);
    addTable(['Cliente'], [[cliente?.nombreComercial]], 34, { left: 14 }, 60, document);
    addTable(['Pago', 'Moneda'], [[total, tipoMoneda.codigoMoneda]], 50, { left: 14 }, 30, document);

    const headers = ['Producto', 'Unidad', 'Cantidad', 'Kg', 'Descuento', 'Precio venta', 'Importe']
    const dataProduct = itemVenta.map((item) => {
        return [ item.producto.nombre, item.producto.tipoUnidad.nombre, item.cantidad, item.kg, item.descuento, item.precioVenta, item.total ]
    });
    dataProduct.push(['', '', '', '', '',  'Total', total])
    addTable(headers, dataProduct, 80, { left: 10 }, 28, document)
    document.autoPrint({ variant: 'non-conform' });
    const blobPDF = new Blob([document.output('blob')], { type: 'application/pdf' });
    return URL.createObjectURL(blobPDF);
    // document.save(`Detalle de venta ${ticketFolio}.pdf`)
}

export default generatePDFVenta